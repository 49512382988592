<div style="text-align: center;">
  <img width="250px" src="https://facturacion.totalsportsas.com/img/logo_totalsport-02.jpg">
</div>
<div class="card card-body" style="width: 70%; text-align: center;margin: auto;max-width: 1000px;">
  <h3>Completa el formulario</h3>
    <form [formGroup]="perfil">
      <div class="wrapper">
        <div>
          <div class="left">Razon Social</div>
          <div>
            <input class="form-control" id="razonSocial" type="text" formControlName="razonSocial" required>
          </div>
        </div>
        <div> 
        <div class="left"> Tipo documento  </div>
        <div>
          <select class="form-control" formControlName="tipoDocumento" name="" id="" required>
            <option value="0">Seleccionar</option>
            <option value="cedula">Cedula</option>
            <option value="nit">Nit</option>
            <option value="pasaporte">Pasaporte</option>
            <option value="cedulaExtranjera">Cedula Extranjera</option>
            <option value="otro">Otro</option>
          </select>
        </div>
        </div>
        <div> 
          <div class="left"> Documento </div>
          <div>
            <input type="number" formControlName="documento" class="form-control" required>
            <input maxlength="1" style="width:100px ;" placeholder="DV" *ngIf="perfil.get('tipoDocumento')?.value == 'nit'" type="number" formControlName="dv" class="form-control">
          </div>
        </div>    
        <div> 
          <div class="left"> Regimen  </div>
          <div>
            <select class="form-control" formControlName="regimen" required>
              <option value="0">Seleccionar</option>
              <option value="simplificado">Simplificado</option>
              <option value="comun">Comun</option>
            </select>
          </div>
        </div>
        <div>
          <div class="left"> Actividad Economica </div>
          <div> <input class="form-control" formControlName="actividadEconomica" type="number" maxlength="7" required> </div>
        </div>
        <div>
          <div class="left">Email</div>
          <div><input type="email" formControlName="email"  class="form-control" required></div>
        </div>
        <div>
          <div class="left">Telefono</div>
          <div> <input type="number" formControlName="telefono" class="form-control" required> </div>
        </div>
        <div>
          <div class="left">Celular</div>
          <div> <input type="number" formControlName="celular" class="form-control" required> </div>
        </div>
        <div>
          <div class="left">Direccion</div>
          <div><input type="text" formControlName="direccion" class="form-control" required></div>
        </div>
        <div>
          <div class="left">Ciudad</div>
          <div>
            <select class="form-control" formControlName="ciudad" required>
              <option value="0">Seleccionar</option>
              <option value="1">Medellin</option>
              <option value="2">Abejorral</option>
              <option value="3">Abriaqui</option>
              <option value="4">Alejandria</option>
              <option value="5">Amaga</option>
              <option value="6">Amalfi</option>
              <option value="7">Andes</option>
              <option value="8">Angelopolis</option>
              <option value="9">Angostura</option>
              <option value="10">Anori</option>
              <option value="11">Santa Fe De Antioquia</option>
              <option value="12">Anza</option>
              <option value="13">Apartado</option>
              <option value="14">Arboletes</option>
              <option value="15">Argelia</option>
              <option value="16">Armenia</option>
              <option value="17">Barbosa</option>
              <option value="18">Belmira</option>
              <option value="19">Bello</option>
              <option value="20">Betania</option>
              <option value="21">Betulia</option>
              <option value="22">Ciudad Bolivar </option>
              <option value="23">Briceño</option>
              <option value="24">Buritica</option>
              <option value="25">Caceres</option>
              <option value="26">Caicedo</option>
              <option value="27">Caldas</option>
              <option value="28">Campamento</option>
              <option value="29">Cañasgordas</option>
              <option value="30">Caracoli</option>
              <option value="31">Caramanta</option>
              <option value="32">Carepa</option>
              <option value="33">El Carmen De Viboral</option>
              <option value="34">Carolina</option>
              <option value="35">Caucasia</option>
              <option value="36">Chigorodo</option>
              <option value="37">Cisneros</option>
              <option value="38">Cocorna</option>
              <option value="39">Concepcion</option>
              <option value="40">Concordia</option>
              <option value="41">Copacabana</option>
              <option value="42">Dabeiba</option>
              <option value="43">Donmatias</option>
              <option value="44">Ebejico</option>
              <option value="45">El Bagre </option>
              <option value="46">Entrerrios</option>
              <option value="47">Envigado</option>
              <option value="48">Fredonia</option>
              <option value="49">Frontino</option>
              <option value="50">Giraldo</option>
              <option value="51">Girardota</option>
              <option value="52">Gomez Plata </option>
              <option value="53">Granada</option>
              <option value="54">Guadalupe</option>
              <option value="55">Guarne</option>
              <option value="56">Guatape</option>
              <option value="57">Heliconia</option>
              <option value="58">Hispania</option>
              <option value="59">Itagui</option>
              <option value="60">Ituango</option>
              <option value="61">Jardin</option>
              <option value="62">Jerico</option>
              <option value="63">La Ceja </option>
              <option value="64">La Estrella </option>
              <option value="65">La Pintada </option>
              <option value="66">La Union </option>
              <option value="67">Liborina</option>
              <option value="68">Maceo</option>
              <option value="69">Marinilla</option>
              <option value="70">Montebello</option>
              <option value="71">Murindo</option>
              <option value="72">Mutata</option>
              <option value="73">Nariño</option>
              <option value="74">Necocli</option>
              <option value="75">Nechi</option>
              <option value="76">Olaya</option>
              <option value="77">Peñol</option>
              <option value="78">Peque</option>
              <option value="79">Pueblorrico</option>
              <option value="80">Puerto Berrio </option>
              <option value="81">Puerto Nare </option>
              <option value="82">Puerto Triunfo </option>
              <option value="83">Remedios</option>
              <option value="84">Retiro</option>
              <option value="85">Rionegro</option>
              <option value="86">Sabanalarga</option>
              <option value="87">Sabaneta</option>
              <option value="88">Salgar</option>
              <option value="89">San Andres De Cuerquia</option>
              <option value="90">San Carlos </option>
              <option value="91">San Francisco </option>
              <option value="92">San Jeronimo </option>
              <option value="93">San Jose De La Montaña </option>
              <option value="94">San Juan De Uraba</option>
              <option value="95">San Luis </option>
              <option value="96">San Pedro De Los Milagros </option>
              <option value="97">San Pedro De Uraba</option>
              <option value="98">San Rafael </option>
              <option value="99">San Roque </option>
              <option value="100">San Vicente Ferrer</option>
              <option value="101">Santa Barbara </option>
              <option value="102">Santa Rosa De Osos</option>
              <option value="103">Santo Domingo </option>
              <option value="104">El Santuario </option>
              <option value="105">Segovia</option>
              <option value="106">Sonson</option>
              <option value="107">Sopetran</option>
              <option value="108">Tamesis</option>
              <option value="109">Taraza</option>
              <option value="110">Tarso</option>
              <option value="111">Titiribi</option>
              <option value="112">Toledo</option>
              <option value="113">Turbo</option>
              <option value="114">Uramita</option>
              <option value="115">Urrao</option>
              <option value="116">Valdivia</option>
              <option value="117">Valparaiso</option>
              <option value="118">Vegachi</option>
              <option value="119">Venecia</option>
              <option value="120">Vigia Del Fuerte</option>
              <option value="121">Yali</option>
              <option value="122">Yarumal</option>
              <option value="123">Yolombo</option>
              <option value="124">Yondo</option>
              <option value="125">Zaragoza</option>
              <option value="126">Barranquilla</option>
              <option value="127">Baranoa</option>
              <option value="128">Campo De La Cruz</option>
              <option value="129">Candelaria</option>
              <option value="130">Galapa</option>
              <option value="131">Juan De Acosta</option>
              <option value="132">Luruaco</option>
              <option value="133">Malambo</option>
              <option value="134">Manati</option>
              <option value="135">Palmar De Varela</option>
              <option value="136">Piojo</option>
              <option value="137">Polonuevo</option>
              <option value="138">Ponedera</option>
              <option value="139">Puerto Colombia </option>
              <option value="140">Repelon</option>
              <option value="141">Sabanagrande</option>
              <option value="142">Sabanalarga</option>
              <option value="143">Santa Lucia </option>
              <option value="144">Santo Tomas </option>
              <option value="145">Soledad</option>
              <option value="146">Suan</option>
              <option value="147">Tubara</option>
              <option value="148">Usiacuri</option>
              <option value="149">Bogota, D.c. </option>
              <option value="150">Cartagena De Indias</option>
              <option value="151">Achi</option>
              <option value="152">Altos Del Rosario</option>
              <option value="153">Arenal</option>
              <option value="154">Arjona</option>
              <option value="155">Arroyohondo</option>
              <option value="156">Barranco De Loba</option>
              <option value="157">Calamar</option>
              <option value="158">Cantagallo</option>
              <option value="159">Cicuco</option>
              <option value="160">Cordoba</option>
              <option value="161">Clemencia</option>
              <option value="162">El Carmen De Bolivar</option>
              <option value="163">El Guamo </option>
              <option value="164">El Peñon </option>
              <option value="165">Hatillo De Loba</option>
              <option value="166">Magangue</option>
              <option value="167">Mahates</option>
              <option value="168">Margarita</option>
              <option value="169">Maria La Baja</option>
              <option value="170">Montecristo</option>
              <option value="171">Mompos</option>
              <option value="172">Morales</option>
              <option value="173">Norosi</option>
              <option value="174">Pinillos</option>
              <option value="175">Regidor</option>
              <option value="176">Rio Viejo </option>
              <option value="177">San Cristobal </option>
              <option value="178">San Estanislao </option>
              <option value="179">San Fernando </option>
              <option value="180">San Jacinto </option>
              <option value="181">San Jacinto Del Cauca</option>
              <option value="182">San Juan Nepomuceno</option>
              <option value="183">San Martin De Loba</option>
              <option value="184">San Pablo </option>
              <option value="185">Santa Catalina </option>
              <option value="186">Santa Rosa </option>
              <option value="187">Santa Rosa Del Sur</option>
              <option value="188">Simiti</option>
              <option value="189">Soplaviento</option>
              <option value="190">Talaigua Nuevo </option>
              <option value="191">Tiquisio</option>
              <option value="192">Turbaco</option>
              <option value="193">Turbana</option>
              <option value="194">Villanueva</option>
              <option value="195">Zambrano</option>
              <option value="196">Tunja</option>
              <option value="197">Almeida</option>
              <option value="198">Aquitania</option>
              <option value="199">Arcabuco</option>
              <option value="200">Belen</option>
              <option value="201">Berbeo</option>
              <option value="202">Beteitiva</option>
              <option value="203">Boavita</option>
              <option value="204">Boyaca</option>
              <option value="205">Briceño</option>
              <option value="206">Buenavista</option>
              <option value="207">Busbanza</option>
              <option value="208">Caldas</option>
              <option value="209">Campohermoso</option>
              <option value="210">Cerinza</option>
              <option value="211">Chinavita</option>
              <option value="212">Chiquinquira</option>
              <option value="213">Chiscas</option>
              <option value="214">Chita</option>
              <option value="215">Chitaraque</option>
              <option value="216">Chivata</option>
              <option value="217">Cienega</option>
              <option value="218">Combita</option>
              <option value="219">Coper</option>
              <option value="220">Corrales</option>
              <option value="221">Covarachia</option>
              <option value="222">Cubara</option>
              <option value="223">Cucaita</option>
              <option value="224">Cuitiva</option>
              <option value="225">Chiquiza</option>
              <option value="226">Chivor</option>
              <option value="227">Duitama</option>
              <option value="228">El Cocuy </option>
              <option value="229">El Espino </option>
              <option value="230">Firavitoba</option>
              <option value="231">Floresta</option>
              <option value="232">Gachantiva</option>
              <option value="233">Gameza</option>
              <option value="234">Garagoa</option>
              <option value="235">Guacamayas</option>
              <option value="236">Guateque</option>
              <option value="237">Guayata</option>
              <option value="238">Guican De La Sierra</option>
              <option value="239">Iza</option>
              <option value="240">Jenesano</option>
              <option value="241">Jerico</option>
              <option value="242">Labranzagrande</option>
              <option value="243">La Capilla </option>
              <option value="244">La Victoria </option>
              <option value="245">La Uvita </option>
              <option value="246">Villa De Leyva</option>
              <option value="247">Macanal</option>
              <option value="248">Maripi</option>
              <option value="249">Miraflores</option>
              <option value="250">Mongua</option>
              <option value="251">Mongui</option>
              <option value="252">Moniquira</option>
              <option value="253">Motavita</option>
              <option value="254">Muzo</option>
              <option value="255">Nobsa</option>
              <option value="256">Nuevo Colon </option>
              <option value="257">Oicata</option>
              <option value="258">Otanche</option>
              <option value="259">Pachavita</option>
              <option value="260">Paez</option>
              <option value="261">Paipa</option>
              <option value="262">Pajarito</option>
              <option value="263">Panqueba</option>
              <option value="264">Pauna</option>
              <option value="265">Paya</option>
              <option value="266">Paz De Rio</option>
              <option value="267">Pesca</option>
              <option value="268">Pisba</option>
              <option value="269">Puerto Boyaca </option>
              <option value="270">Quipama</option>
              <option value="271">Ramiriqui</option>
              <option value="272">Raquira</option>
              <option value="273">Rondon</option>
              <option value="274">Saboya</option>
              <option value="275">Sachica</option>
              <option value="276">Samaca</option>
              <option value="277">San Eduardo </option>
              <option value="278">San Jose De Pare</option>
              <option value="279">San Luis De Gaceno</option>
              <option value="280">San Mateo </option>
              <option value="281">San Miguel De Sema</option>
              <option value="282">San Pablo De Borbur</option>
              <option value="283">Santana</option>
              <option value="284">Santa Maria </option>
              <option value="285">Santa Rosa De Viterbo</option>
              <option value="286">Santa Sofia </option>
              <option value="287">Sativanorte</option>
              <option value="288">Sativasur</option>
              <option value="289">Siachoque</option>
              <option value="290">Soata</option>
              <option value="291">Socota</option>
              <option value="292">Socha</option>
              <option value="293">Sogamoso</option>
              <option value="294">Somondoco</option>
              <option value="295">Sora</option>
              <option value="296">Sotaquira</option>
              <option value="297">Soraca</option>
              <option value="298">Susacon</option>
              <option value="299">Sutamarchan</option>
              <option value="300">Sutatenza</option>
              <option value="301">Tasco</option>
              <option value="302">Tenza</option>
              <option value="303">Tibana</option>
              <option value="304">Tibasosa</option>
              <option value="305">Tinjaca</option>
              <option value="306">Tipacoque</option>
              <option value="307">Toca</option>
              <option value="308">Togui</option>
              <option value="309">Topaga</option>
              <option value="310">Tota</option>
              <option value="311">Tunungua</option>
              <option value="312">Turmeque</option>
              <option value="313">Tuta</option>
              <option value="314">Tutaza</option>
              <option value="315">fmbita</option>
              <option value="316">Ventaquemada</option>
              <option value="317">Viracacha</option>
              <option value="318">Zetaquira</option>
              <option value="319">Manizales</option>
              <option value="320">Aguadas</option>
              <option value="321">Anserma</option>
              <option value="322">Aranzazu</option>
              <option value="323">Belalcazar</option>
              <option value="324">Chinchina</option>
              <option value="325">Filadelfia</option>
              <option value="326">La Dorada </option>
              <option value="327">La Merced </option>
              <option value="328">Manzanares</option>
              <option value="329">Marmato</option>
              <option value="330">Marquetalia</option>
              <option value="331">Marulanda</option>
              <option value="332">Neira</option>
              <option value="333">Norcasia</option>
              <option value="334">Pacora</option>
              <option value="335">Palestina</option>
              <option value="336">Pensilvania</option>
              <option value="337">Riosucio</option>
              <option value="338">Risaralda</option>
              <option value="339">Salamina</option>
              <option value="340">Samana</option>
              <option value="341">San Jose </option>
              <option value="342">Supia</option>
              <option value="343">Victoria</option>
              <option value="344">Villamaria</option>
              <option value="345">Viterbo</option>
              <option value="346">Florencia</option>
              <option value="347">Albania</option>
              <option value="348">Belen De Los Andaquies</option>
              <option value="349">Cartagena Del Chaira</option>
              <option value="350">Curillo</option>
              <option value="351">El Doncello </option>
              <option value="352">El Paujil </option>
              <option value="353">La Montañita </option>
              <option value="354">Milan</option>
              <option value="355">Morelia</option>
              <option value="356">Puerto Rico </option>
              <option value="357">San Jose Del Fragua</option>
              <option value="358">San Vicente Del Caguan</option>
              <option value="359">Solano</option>
              <option value="360">Solita</option>
              <option value="361">Valparaiso</option>
              <option value="362">Popayan</option>
              <option value="363">Almaguer</option>
              <option value="364">Argelia</option>
              <option value="365">Balboa</option>
              <option value="366">Bolivar</option>
              <option value="367">Buenos Aires </option>
              <option value="368">Cajibio</option>
              <option value="369">Caldono</option>
              <option value="370">Caloto</option>
              <option value="371">Corinto</option>
              <option value="372">El Tambo </option>
              <option value="373">Florencia</option>
              <option value="374">Guachene</option>
              <option value="375">Guapi</option>
              <option value="376">Inza</option>
              <option value="377">Jambalo</option>
              <option value="378">La Sierra </option>
              <option value="379">La Vega </option>
              <option value="380">Lopez De Micay</option>
              <option value="381">Mercaderes</option>
              <option value="382">Miranda</option>
              <option value="383">Morales</option>
              <option value="384">Padilla</option>
              <option value="385">Paez</option>
              <option value="386">Patia</option>
              <option value="387">Piamonte</option>
              <option value="388">Piendamo - Tunia</option>
              <option value="389">Puerto Tejada </option>
              <option value="390">Purace</option>
              <option value="391">Rosas</option>
              <option value="392">San Sebastian </option>
              <option value="393">Santander De Quilichao</option>
              <option value="394">Santa Rosa </option>
              <option value="395">Silvia</option>
              <option value="396">Sotara</option>
              <option value="397">Suarez</option>
              <option value="398">Sucre</option>
              <option value="399">Timbio</option>
              <option value="400">Timbiqui</option>
              <option value="401">Toribio</option>
              <option value="402">Totoro</option>
              <option value="403">Villa Rica </option>
              <option value="404">Valledupar</option>
              <option value="405">Aguachica</option>
              <option value="406">Agustin Codazzi </option>
              <option value="407">Astrea</option>
              <option value="408">Becerril</option>
              <option value="409">Bosconia</option>
              <option value="410">Chimichagua</option>
              <option value="411">Chiriguana</option>
              <option value="412">Curumani</option>
              <option value="413">El Copey </option>
              <option value="414">El Paso </option>
              <option value="415">Gamarra</option>
              <option value="416">Gonzalez</option>
              <option value="417">La Gloria </option>
              <option value="418">La Jagua De Ibirico</option>
              <option value="419">Manaure Balcon Del Cesar</option>
              <option value="420">Pailitas</option>
              <option value="421">Pelaya</option>
              <option value="422">Pueblo Bello </option>
              <option value="423">Rio De Oro</option>
              <option value="424">La Paz </option>
              <option value="425">San Alberto </option>
              <option value="426">San Diego </option>
              <option value="427">San Martin </option>
              <option value="428">Tamalameque</option>
              <option value="429">Monteria</option>
              <option value="430">Ayapel</option>
              <option value="431">Buenavista</option>
              <option value="432">Canalete</option>
              <option value="433">Cerete</option>
              <option value="434">Chima</option>
              <option value="435">Chinu</option>
              <option value="436">Cienaga De Oro</option>
              <option value="437">Cotorra</option>
              <option value="438">La Apartada </option>
              <option value="439">Lorica</option>
              <option value="440">Los Cordobas </option>
              <option value="441">Momil</option>
              <option value="442">Montelibano</option>
              <option value="443">Moñitos</option>
              <option value="444">Planeta Rica </option>
              <option value="445">Pueblo Nuevo </option>
              <option value="446">Puerto Escondido </option>
              <option value="447">Puerto Libertador </option>
              <option value="448">Purisima De La Concepcion</option>
              <option value="449">Sahagun</option>
              <option value="450">San Andres De Sotavento</option>
              <option value="451">San Antero </option>
              <option value="452">San Bernardo Del Viento</option>
              <option value="453">San Carlos </option>
              <option value="454">San Jose De Ure</option>
              <option value="455">San Pelayo </option>
              <option value="456">Tierralta</option>
              <option value="457">Tuchin</option>
              <option value="458">Valencia</option>
              <option value="459">Agua De Dios</option>
              <option value="460">Alban</option>
              <option value="461">Anapoima</option>
              <option value="462">Anolaima</option>
              <option value="463">Arbelaez</option>
              <option value="464">Beltran</option>
              <option value="465">Bituima</option>
              <option value="466">Bojaca</option>
              <option value="467">Cabrera</option>
              <option value="468">Cachipay</option>
              <option value="469">Cajica</option>
              <option value="470">Caparrapi</option>
              <option value="471">Caqueza</option>
              <option value="472">Carmen De Carupa</option>
              <option value="473">Chaguani</option>
              <option value="474">Chia</option>
              <option value="475">Chipaque</option>
              <option value="476">Choachi</option>
              <option value="477">Choconta</option>
              <option value="478">Cogua</option>
              <option value="479">Cota</option>
              <option value="480">Cucunuba</option>
              <option value="481">El Colegio </option>
              <option value="482">El Peñon </option>
              <option value="483">El Rosal </option>
              <option value="484">Facatativa</option>
              <option value="485">Fomeque</option>
              <option value="486">Fosca</option>
              <option value="487">Funza</option>
              <option value="488">Fuquene</option>
              <option value="489">Fusagasuga</option>
              <option value="490">Gachala</option>
              <option value="491">Gachancipa</option>
              <option value="492">Gacheta</option>
              <option value="493">Gama</option>
              <option value="494">Girardot</option>
              <option value="495">Granada</option>
              <option value="496">Guacheta</option>
              <option value="497">Guaduas</option>
              <option value="498">Guasca</option>
              <option value="499">Guataqui</option>
              <option value="500">Guatavita</option>
              <option value="501">Guayabal De Siquima</option>
              <option value="502">Guayabetal</option>
              <option value="503">Gutierrez</option>
              <option value="504">Jerusalen</option>
              <option value="505">Junin</option>
              <option value="506">La Calera </option>
              <option value="507">La Mesa </option>
              <option value="508">La Palma </option>
              <option value="509">La Peña </option>
              <option value="510">La Vega </option>
              <option value="511">Lenguazaque</option>
              <option value="512">Macheta</option>
              <option value="513">Madrid</option>
              <option value="514">Manta</option>
              <option value="515">Medina</option>
              <option value="516">Mosquera</option>
              <option value="517">Nariño</option>
              <option value="518">Nemocon</option>
              <option value="519">Nilo</option>
              <option value="520">Nimaima</option>
              <option value="521">Nocaima</option>
              <option value="522">Venecia</option>
              <option value="523">Pacho</option>
              <option value="524">Paime</option>
              <option value="525">Pandi</option>
              <option value="526">Paratebueno</option>
              <option value="527">Pasca</option>
              <option value="528">Puerto Salgar </option>
              <option value="529">Puli</option>
              <option value="530">Quebradanegra</option>
              <option value="531">Quetame</option>
              <option value="532">Quipile</option>
              <option value="533">Apulo</option>
              <option value="534">Ricaurte</option>
              <option value="535">San Antonio Del Tequendama</option>
              <option value="536">San Bernardo </option>
              <option value="537">San Cayetano </option>
              <option value="538">San Francisco </option>
              <option value="539">San Juan De Rioseco</option>
              <option value="540">Sasaima</option>
              <option value="541">Sesquile</option>
              <option value="542">Sibate</option>
              <option value="543">Silvania</option>
              <option value="544">Simijaca</option>
              <option value="545">Soacha</option>
              <option value="546">Sopo</option>
              <option value="547">Subachoque</option>
              <option value="548">Suesca</option>
              <option value="549">Supata</option>
              <option value="550">Susa</option>
              <option value="551">Sutatausa</option>
              <option value="552">Tabio</option>
              <option value="553">Tausa</option>
              <option value="554">Tena</option>
              <option value="555">Tenjo</option>
              <option value="556">Tibacuy</option>
              <option value="557">Tibirita</option>
              <option value="558">Tocaima</option>
              <option value="559">Tocancipa</option>
              <option value="560">Topaipi</option>
              <option value="561">Ubala</option>
              <option value="562">Ubaque</option>
              <option value="563">Villa De San Diego De Ubate</option>
              <option value="564">Une</option>
              <option value="565">Utica</option>
              <option value="566">Vergara</option>
              <option value="567">Viani</option>
              <option value="568">Villagomez</option>
              <option value="569">Villapinzon</option>
              <option value="570">Villeta</option>
              <option value="571">Viota</option>
              <option value="572">Yacopi</option>
              <option value="573">Zipacon</option>
              <option value="574">Zipaquira</option>
              <option value="575">Quibdo</option>
              <option value="576">Acandi</option>
              <option value="577">Alto Baudo </option>
              <option value="578">Atrato</option>
              <option value="579">Bagado</option>
              <option value="580">Bahia Solano </option>
              <option value="581">Bajo Baudo </option>
              <option value="582">Bojaya</option>
              <option value="583">El Canton Del San Pablo </option>
              <option value="584">Carmen Del Darien</option>
              <option value="585">Certegui</option>
              <option value="586">Condoto</option>
              <option value="587">El Carmen De Atrato</option>
              <option value="588">El Litoral Del San Juan </option>
              <option value="589">Istmina</option>
              <option value="590">Jurado</option>
              <option value="591">Lloro</option>
              <option value="592">Medio Atrato </option>
              <option value="593">Medio Baudo </option>
              <option value="594">Medio San Juan</option>
              <option value="595">Novita</option>
              <option value="596">Nuqui</option>
              <option value="597">Rio Iro </option>
              <option value="598">Rio Quito </option>
              <option value="599">Riosucio</option>
              <option value="600">San Jose Del Palmar</option>
              <option value="601">Sipi</option>
              <option value="602">Tado</option>
              <option value="603">Unguia</option>
              <option value="604">Union Panamericana </option>
              <option value="605">Neiva</option>
              <option value="606">Acevedo</option>
              <option value="607">Agrado</option>
              <option value="608">Aipe</option>
              <option value="609">Algeciras</option>
              <option value="610">Altamira</option>
              <option value="611">Baraya</option>
              <option value="612">Campoalegre</option>
              <option value="613">Colombia</option>
              <option value="614">Elias</option>
              <option value="615">Garzon</option>
              <option value="616">Gigante</option>
              <option value="617">Guadalupe</option>
              <option value="618">Hobo</option>
              <option value="619">aquira</option>
              <option value="620">Isnos</option>
              <option value="621">La Argentina </option>
              <option value="622">La Plata </option>
              <option value="623">Nataga</option>
              <option value="624">Oporapa</option>
              <option value="625">Paicol</option>
              <option value="626">Palermo</option>
              <option value="627">Palestina</option>
              <option value="628">Pital</option>
              <option value="629">Pitalito</option>
              <option value="630">Rivera</option>
              <option value="631">Saladoblanco</option>
              <option value="632">San Agustin </option>
              <option value="633">Santa Maria </option>
              <option value="634">Suaza</option>
              <option value="635">Tarqui</option>
              <option value="636">Tesalia</option>
              <option value="637">Tello</option>
              <option value="638">Teruel</option>
              <option value="639">Timana</option>
              <option value="640">Villavieja</option>
              <option value="641">Yaguara</option>
              <option value="642">Riohacha</option>
              <option value="643">Albania</option>
              <option value="644">Barrancas</option>
              <option value="645">Dibulla</option>
              <option value="646">Distraccion</option>
              <option value="647">El Molino </option>
              <option value="648">Fonseca</option>
              <option value="649">Hatonuevo</option>
              <option value="650">La Jagua Del Pilar</option>
              <option value="651">Maicao</option>
              <option value="652">Manaure</option>
              <option value="653">San Juan Del Cesar</option>
              <option value="654">Uribia</option>
              <option value="655">Urumita</option>
              <option value="656">Villanueva</option>
              <option value="657">Santa Marta </option>
              <option value="658">Algarrobo</option>
              <option value="659">Aracataca</option>
              <option value="660">Ariguani</option>
              <option value="661">Cerro De San Antonio</option>
              <option value="662">Chivolo</option>
              <option value="663">Cienaga</option>
              <option value="664">Concordia</option>
              <option value="665">El Banco </option>
              <option value="666">El Piñon </option>
              <option value="667">El Reten </option>
              <option value="668">Fundacion</option>
              <option value="669">Guamal</option>
              <option value="670">Nueva Granada </option>
              <option value="671">Pedraza</option>
              <option value="672">Pijiño Del Carmen</option>
              <option value="673">Pivijay</option>
              <option value="674">Plato</option>
              <option value="675">Puebloviejo</option>
              <option value="676">Remolino</option>
              <option value="677">Sabanas De San angel</option>
              <option value="678">Salamina</option>
              <option value="679">San Sebastian De Buenavista</option>
              <option value="680">San Zenon </option>
              <option value="681">Santa Ana </option>
              <option value="682">Santa Barbara De Pinto</option>
              <option value="683">Sitionuevo</option>
              <option value="684">Tenerife</option>
              <option value="685">Zapayan</option>
              <option value="686">Zona Bananera </option>
              <option value="687">Villavicencio</option>
              <option value="688">Acacias</option>
              <option value="689">Barranca De Upia</option>
              <option value="690">Cabuyaro</option>
              <option value="691">Castilla La Nueva</option>
              <option value="692">Cubarral</option>
              <option value="693">Cumaral</option>
              <option value="694">El Calvario </option>
              <option value="695">El Castillo </option>
              <option value="696">El Dorado </option>
              <option value="697">Fuente De Oro</option>
              <option value="698">Granada</option>
              <option value="699">Guamal</option>
              <option value="700">Mapiripan</option>
              <option value="701">Mesetas</option>
              <option value="702">La Macarena </option>
              <option value="703">Uribe</option>
              <option value="704">Lejanias</option>
              <option value="705">Puerto Concordia </option>
              <option value="706">Puerto Gaitan </option>
              <option value="707">Puerto Lopez </option>
              <option value="708">Puerto Lleras </option>
              <option value="709">Puerto Rico </option>
              <option value="710">Restrepo</option>
              <option value="711">San Carlos De Guaroa</option>
              <option value="712">San Juan De Arama</option>
              <option value="713">San Juanito </option>
              <option value="714">San Martin </option>
              <option value="715">Vistahermosa</option>
              <option value="716">Pasto</option>
              <option value="717">Alban</option>
              <option value="718">Aldana</option>
              <option value="719">Ancuya</option>
              <option value="720">Arboleda</option>
              <option value="721">Barbacoas</option>
              <option value="722">Belen</option>
              <option value="723">Buesaco</option>
              <option value="724">Colon</option>
              <option value="725">Consaca</option>
              <option value="726">Contadero</option>
              <option value="727">Cordoba</option>
              <option value="728">Cuaspud</option>
              <option value="729">Cumbal</option>
              <option value="730">Cumbitara</option>
              <option value="731">Chachagui</option>
              <option value="732">El Charco </option>
              <option value="733">El Peñol </option>
              <option value="734">El Rosario </option>
              <option value="735">El Tablon De Gomez</option>
              <option value="736">El Tambo </option>
              <option value="737">Funes</option>
              <option value="738">Guachucal</option>
              <option value="739">Guaitarilla</option>
              <option value="740">Gualmatan</option>
              <option value="741">Iles</option>
              <option value="742">Imues</option>
              <option value="743">Ipiales</option>
              <option value="744">La Cruz </option>
              <option value="745">La Florida </option>
              <option value="746">La Llanada </option>
              <option value="747">La Tola </option>
              <option value="748">La Union </option>
              <option value="749">Leiva</option>
              <option value="750">Linares</option>
              <option value="751">Los Andes </option>
              <option value="752">Magui</option>
              <option value="753">Mallama</option>
              <option value="754">Mosquera</option>
              <option value="755">Nariño</option>
              <option value="756">Olaya Herrera </option>
              <option value="757">Ospina</option>
              <option value="758">Francisco Pizarro </option>
              <option value="759">Policarpa</option>
              <option value="760">Potosi</option>
              <option value="761">Providencia</option>
              <option value="762">Puerres</option>
              <option value="763">Pupiales</option>
              <option value="764">Ricaurte</option>
              <option value="765">Roberto Payan </option>
              <option value="766">Samaniego</option>
              <option value="767">Sandona</option>
              <option value="768">San Bernardo </option>
              <option value="769">San Lorenzo </option>
              <option value="770">San Pablo </option>
              <option value="771">San Pedro De Cartago</option>
              <option value="772">Santa Barbara </option>
              <option value="773">Santacruz</option>
              <option value="774">Sapuyes</option>
              <option value="775">Taminango</option>
              <option value="776">Tangua</option>
              <option value="777">San Andres De Tumaco</option>
              <option value="778">Tuquerres</option>
              <option value="779">Yacuanquer</option>
              <option value="780">San Jose De Cucuta</option>
              <option value="781">abrego</option>
              <option value="782">Arboledas</option>
              <option value="783">Bochalema</option>
              <option value="784">Bucarasica</option>
              <option value="785">Cacota</option>
              <option value="786">Cachira</option>
              <option value="787">Chinacota</option>
              <option value="788">Chitaga</option>
              <option value="789">Convencion</option>
              <option value="790">Cucutilla</option>
              <option value="791">Durania</option>
              <option value="792">El Carmen </option>
              <option value="793">El Tarra </option>
              <option value="794">El Zulia </option>
              <option value="795">Gramalote</option>
              <option value="796">Hacari</option>
              <option value="797">Herran</option>
              <option value="798">Labateca</option>
              <option value="799">La Esperanza </option>
              <option value="800">La Playa </option>
              <option value="801">Los Patios </option>
              <option value="802">Lourdes</option>
              <option value="803">Mutiscua</option>
              <option value="804">Ocaña</option>
              <option value="805">Pamplona</option>
              <option value="806">Pamplonita</option>
              <option value="807">Puerto Santander </option>
              <option value="808">Ragonvalia</option>
              <option value="809">Salazar</option>
              <option value="810">San Calixto </option>
              <option value="811">San Cayetano </option>
              <option value="812">Santiago</option>
              <option value="813">Sardinata</option>
              <option value="814">Silos</option>
              <option value="815">Teorama</option>
              <option value="816">Tibu</option>
              <option value="817">Toledo</option>
              <option value="818">Villa Caro </option>
              <option value="819">Villa Del Rosario</option>
              <option value="820">Armenia</option>
              <option value="821">Buenavista</option>
              <option value="822">Calarca</option>
              <option value="823">Circasia</option>
              <option value="824">Cordoba</option>
              <option value="825">Filandia</option>
              <option value="826">Genova</option>
              <option value="827">La Tebaida </option>
              <option value="828">Montenegro</option>
              <option value="829">Pijao</option>
              <option value="830">Quimbaya</option>
              <option value="831">Salento</option>
              <option value="832">Pereira</option>
              <option value="833">Apia</option>
              <option value="834">Balboa</option>
              <option value="835">Belen De Umbria</option>
              <option value="836">Dosquebradas</option>
              <option value="837">Guatica</option>
              <option value="838">La Celia </option>
              <option value="839">La Virginia </option>
              <option value="840">Marsella</option>
              <option value="841">Mistrato</option>
              <option value="842">Pueblo Rico </option>
              <option value="843">Quinchia</option>
              <option value="844">Santa Rosa De Cabal</option>
              <option value="845">Santuario</option>
              <option value="846">Bucaramanga</option>
              <option value="847">Aguada</option>
              <option value="848">Albania</option>
              <option value="849">Aratoca</option>
              <option value="850">Barbosa</option>
              <option value="851">Barichara</option>
              <option value="852">Barrancabermeja</option>
              <option value="853">Betulia</option>
              <option value="854">Bolivar</option>
              <option value="855">Cabrera</option>
              <option value="856">California</option>
              <option value="857">Capitanejo</option>
              <option value="858">Carcasi</option>
              <option value="859">Cepita</option>
              <option value="860">Cerrito</option>
              <option value="861">Charala</option>
              <option value="862">Charta</option>
              <option value="863">Chima</option>
              <option value="864">Chipata</option>
              <option value="865">Cimitarra</option>
              <option value="866">Concepcion</option>
              <option value="867">Confines</option>
              <option value="868">Contratacion</option>
              <option value="869">Coromoro</option>
              <option value="870">Curiti</option>
              <option value="871">El Carmen De Chucuri</option>
              <option value="872">El Guacamayo </option>
              <option value="873">El Peñon </option>
              <option value="874">El Playon </option>
              <option value="875">Encino</option>
              <option value="876">Enciso</option>
              <option value="877">Florian</option>
              <option value="878">Floridablanca</option>
              <option value="879">Galan</option>
              <option value="880">Gambita</option>
              <option value="881">Giron</option>
              <option value="882">Guaca</option>
              <option value="883">Guadalupe</option>
              <option value="884">Guapota</option>
              <option value="885">Guavata</option>
              <option value="886">Guepsa</option>
              <option value="887">Hato</option>
              <option value="888">Jesus Maria </option>
              <option value="889">Jordan</option>
              <option value="890">La Belleza </option>
              <option value="891">Landazuri</option>
              <option value="892">La Paz </option>
              <option value="893">Lebrija</option>
              <option value="894">Los Santos </option>
              <option value="895">Macaravita</option>
              <option value="896">Malaga</option>
              <option value="897">Matanza</option>
              <option value="898">Mogotes</option>
              <option value="899">Molagavita</option>
              <option value="900">Ocamonte</option>
              <option value="901">Oiba</option>
              <option value="902">Onzaga</option>
              <option value="903">Palmar</option>
              <option value="904">Palmas Del Socorro</option>
              <option value="905">Paramo</option>
              <option value="906">Piedecuesta</option>
              <option value="907">Pinchote</option>
              <option value="908">Puente Nacional </option>
              <option value="909">Puerto Parra </option>
              <option value="910">Puerto Wilches </option>
              <option value="911">Rionegro</option>
              <option value="912">Sabana De Torres</option>
              <option value="913">San Andres </option>
              <option value="914">San Benito </option>
              <option value="915">San Gil </option>
              <option value="916">San Joaquin </option>
              <option value="917">San Jose De Miranda</option>
              <option value="918">San Miguel </option>
              <option value="919">San Vicente De Chucuri</option>
              <option value="920">Santa Barbara </option>
              <option value="921">Santa Helena Del Opon</option>
              <option value="922">Simacota</option>
              <option value="923">Socorro</option>
              <option value="924">Suaita</option>
              <option value="925">Sucre</option>
              <option value="926">Surata</option>
              <option value="927">Tona</option>
              <option value="928">Valle De San Jose</option>
              <option value="929">Velez</option>
              <option value="930">Vetas</option>
              <option value="931">Villanueva</option>
              <option value="932">Zapatoca</option>
              <option value="933">Sincelejo</option>
              <option value="934">Buenavista</option>
              <option value="935">Caimito</option>
              <option value="936">Coloso</option>
              <option value="937">Corozal</option>
              <option value="938">Coveñas</option>
              <option value="939">Chalan</option>
              <option value="940">El Roble </option>
              <option value="941">Galeras</option>
              <option value="942">Guaranda</option>
              <option value="943">La Union </option>
              <option value="944">Los Palmitos </option>
              <option value="945">Majagual</option>
              <option value="946">Morroa</option>
              <option value="947">Ovejas</option>
              <option value="948">Palmito</option>
              <option value="949">Sampues</option>
              <option value="950">San Benito Abad</option>
              <option value="951">San Juan De Betulia</option>
              <option value="952">San Marcos </option>
              <option value="953">San Onofre </option>
              <option value="954">San Pedro </option>
              <option value="955">San Luis De Since</option>
              <option value="956">Sucre</option>
              <option value="957">Santiago De Tolu</option>
              <option value="958">Tolu Viejo </option>
              <option value="959">Ibague</option>
              <option value="960">Alpujarra</option>
              <option value="961">Alvarado</option>
              <option value="962">Ambalema</option>
              <option value="963">Anzoategui</option>
              <option value="964">Armero</option>
              <option value="965">Ataco</option>
              <option value="966">Cajamarca</option>
              <option value="967">Carmen De Apicala</option>
              <option value="968">Casabianca</option>
              <option value="969">Chaparral</option>
              <option value="970">Coello</option>
              <option value="971">Coyaima</option>
              <option value="972">Cunday</option>
              <option value="973">Dolores</option>
              <option value="974">Espinal</option>
              <option value="975">Falan</option>
              <option value="976">Flandes</option>
              <option value="977">Fresno</option>
              <option value="978">Guamo</option>
              <option value="979">Herveo</option>
              <option value="980">Honda</option>
              <option value="981">Icononzo</option>
              <option value="982">Lerida</option>
              <option value="983">Libano</option>
              <option value="984">San Sebastian De Mariquita</option>
              <option value="985">Melgar</option>
              <option value="986">Murillo</option>
              <option value="987">Natagaima</option>
              <option value="988">Ortega</option>
              <option value="989">Palocabildo</option>
              <option value="990">Piedras</option>
              <option value="991">Planadas</option>
              <option value="992">Prado</option>
              <option value="993">Purificacion</option>
              <option value="994">Rioblanco</option>
              <option value="995">Roncesvalles</option>
              <option value="996">Rovira</option>
              <option value="997">Saldaña</option>
              <option value="998">San Antonio </option>
              <option value="999">San Luis </option>
              <option value="1000">Santa Isabel </option>
              <option value="1001">Suarez</option>
              <option value="1002">Valle De San Juan</option>
              <option value="1003">Venadillo</option>
              <option value="1004">Villahermosa</option>
              <option value="1005">Villarrica</option>
              <option value="1006">Cali</option>
              <option value="1007">Alcala</option>
              <option value="1008">Andalucia</option>
              <option value="1009">Ansermanuevo</option>
              <option value="1010">Argelia</option>
              <option value="1011">Bolivar</option>
              <option value="1012">Buenaventura</option>
              <option value="1013">Guadalajara De Buga</option>
              <option value="1014">Bugalagrande</option>
              <option value="1015">Caicedonia</option>
              <option value="1016">Calima</option>
              <option value="1017">Candelaria</option>
              <option value="1018">Cartago</option>
              <option value="1019">Dagua</option>
              <option value="1020">El aguila </option>
              <option value="1021">El Cairo </option>
              <option value="1022">El Cerrito </option>
              <option value="1023">El Dovio </option>
              <option value="1024">Florida</option>
              <option value="1025">Ginebra</option>
              <option value="1026">Guacari</option>
              <option value="1027">Jamundi</option>
              <option value="1028">La Cumbre </option>
              <option value="1029">La Union </option>
              <option value="1030">La Victoria </option>
              <option value="1031">Obando</option>
              <option value="1032">Palmira</option>
              <option value="1033">Pradera</option>
              <option value="1034">Restrepo</option>
              <option value="1035">Riofrio</option>
              <option value="1036">Roldanillo</option>
              <option value="1037">San Pedro </option>
              <option value="1038">Sevilla</option>
              <option value="1039">Toro</option>
              <option value="1040">Trujillo</option>
              <option value="1041">Tulua</option>
              <option value="1042">Ulloa</option>
              <option value="1043">Versalles</option>
              <option value="1044">Vijes</option>
              <option value="1045">Yotoco</option>
              <option value="1046">Yumbo</option>
              <option value="1047">Zarzal</option>
              <option value="1048">Arauca</option>
              <option value="1049">Arauquita</option>
              <option value="1050">Cravo Norte </option>
              <option value="1051">Fortul</option>
              <option value="1052">Puerto Rondon </option>
              <option value="1053">Saravena</option>
              <option value="1054">Tame</option>
              <option value="1055">Yopal</option>
              <option value="1056">Aguazul</option>
              <option value="1057">Chameza</option>
              <option value="1058">Hato Corozal </option>
              <option value="1059">La Salina </option>
              <option value="1060">Mani</option>
              <option value="1061">Monterrey</option>
              <option value="1062">Nunchia</option>
              <option value="1063">Orocue</option>
              <option value="1064">Paz De Ariporo</option>
              <option value="1065">Pore</option>
              <option value="1066">Recetor</option>
              <option value="1067">Sabanalarga</option>
              <option value="1068">Sacama</option>
              <option value="1069">San Luis De Palenque</option>
              <option value="1070">Tamara</option>
              <option value="1071">Tauramena</option>
              <option value="1072">Trinidad</option>
              <option value="1073">Villanueva</option>
              <option value="1074">Mocoa</option>
              <option value="1075">Colon</option>
              <option value="1076">Orito</option>
              <option value="1077">Puerto Asis </option>
              <option value="1078">Puerto Caicedo </option>
              <option value="1079">Puerto Guzman </option>
              <option value="1080">Puerto Leguizamo </option>
              <option value="1081">Sibundoy</option>
              <option value="1082">San Francisco </option>
              <option value="1083">San Miguel </option>
              <option value="1084">Santiago</option>
              <option value="1085">Valle Del Guamuez</option>
              <option value="1086">Villagarzon</option>
              <option value="1087">San Andres </option>
              <option value="1088">Providencia</option>
              <option value="1089">Leticia</option>
              <option value="1090">El Encanto </option>
              <option value="1091">La Chorrera </option>
              <option value="1092">La Pedrera </option>
              <option value="1093">La Victoria </option>
              <option value="1094">Miriti - Parana</option>
              <option value="1095">Puerto Alegria </option>
              <option value="1096">Puerto Arica </option>
              <option value="1097">Puerto Nariño </option>
              <option value="1098">Puerto Santander </option>
              <option value="1099">Tarapaca</option>
              <option value="1100">Inirida</option>
              <option value="1101">Barranco Minas </option>
              <option value="1102">Mapiripana</option>
              <option value="1103">San Felipe </option>
              <option value="1104">Puerto Colombia </option>
              <option value="1105">La Guadalupe </option>
              <option value="1106">Cacahual</option>
              <option value="1107">Pana Pana </option>
              <option value="1108">Morichal</option>
              <option value="1109">San Jose Del Guaviare</option>
              <option value="1110">Calamar</option>
              <option value="1111">El Retorno </option>
              <option value="1112">Miraflores</option>
              <option value="1113">Mitu</option>
              <option value="1114">Caruru</option>
              <option value="1115">Pacoa</option>
              <option value="1116">Taraira</option>
              <option value="1117">Papunahua</option>
              <option value="1118">Yavarate</option>
              <option value="1119">Puerto Carreño </option>
              <option value="1120">La Primavera </option>
              <option value="1121">Santa Rosalia </option>
              <option value="1122">Cumaribo</option>
      
            </select>
          </div>
        </div>
      </div>
        <div class="custom-file" style="margin: 10px;">
          <input class="custom-file-input" id="file" formControlName="file" name="file" type="file" (change)="subirImagen($event)">
          <label  class="custom-file-label" for="file">RUT: {{this.perfil.get('file')?.value}}</label>
        </div>
        <div class="custom-file" style="margin: 10px;">
          <input  class="custom-file-input" formControlName="file2" id="file2"  type="file" (change)="subirImagen2($event)">
          <label  class="custom-file-label" for="file">Camara de Comercio: {{this.perfil.get('file2')?.value}}</label>
        </div>
        <div class="custom-file" style="margin: 10px;">
          <input class="custom-file-input" formControlName="file3" id="file3"  type="file" (change)="subirImagen3($event)">
          <label  class="custom-file-label" for="file">Factura: {{this.perfil.get('file3')?.value}}</label>
        </div>

      <div class="wrapper" style="text-align: left;margin-top: 10px;margin: auto;width: 100%;grid-auto-rows: minmax(auto, auto)!important;">
        <div><a><input type="checkbox" formControlName="responsableIva"> Responsable de iva</a></div>
        <div><a><input type="checkbox" formControlName="autoRetenedor"> Autoretenedor</a></div>
        <div><a><input type="checkbox" formControlName="granContribuyente"> Gran Contribuyente</a></div>
      </div>
      <div style="margin: 10px;">
        <a href="#" (click)="modal_visible()"><input type="checkbox" formControlName="confirm">Acepto terminos y condiciones</a>
      </div>
      <div class="modal" id="modal" *ngIf="perfil.get('confirm2')?.value=='1'">
        <div class="modal-content">
          <div><h3>Terminos y condiciones</h3></div>
          <div class="texto-modal">
            <div>
            <div style="text-align: center;">  \/ Asegurate de leer los terminos para poder continuar \/ </div>
            1. ALCANCE 
            La Política de Tratamiento y Protección de Datos Personales presentada a continuación, se aplicará a todas las Bases de Datos y/o Archivos que contengan datos personales y que maneje la empresa TOTALSPORT SAS considerado como responsable del tratamiento de datos personales.
            <br><br>2. IDENTIFICACIÓN DEL RESPONSABLE Y/O ENCARGADO DEL TRATAMIENTO DE
            DATOS PERSONALES
            TOTALSPORT SAS con domicilio en la Transversal 93 #53 – 48 Bodega 75/76, identificado con NIT900464953-1, teléfono de atención 3809490 Bogotá - Colombia.
            Correo electrónico: cservices@totalsport.sas.com
            <br><br>3. DEFINICIONES
            <br>• Aviso de Privacidad: Comunicación verbal o escrita generada por el Responsable, dirigida al Titular para el tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las Políticas de Tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del tratamiento que se pretende dar a los datos personales.
            <br>• Base de Datos: Conjunto organizado de datos personales que sea objeto de tratamiento.
            <br>• Dato Personal: Cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.
            <br>• Dato Sensible: Información que afectan la intimidad de las personas o cuyo uso indebido puede generar discriminación (Origen racial o étnico, orientación política, convicciones filosóficas o religiosas, pertinencia a sindicatos u organizaciones sociales o derechos humanos, datos de salud, vida sexual y biométricos)
            <br>• Encargado del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el tratamiento de datos personales por cuenta del Responsable del Tratamiento.
            <br>• Responsable del Tratamiento: Persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la base de datos y/o el tratamiento de los datos.
            <br>• Titular: Persona natural cuyos datos personales sean objeto de tratamiento.
            <br>• Tratamiento: Cualquier operación o conjunto de operaciones sobre datos personales, tales como la recolección, almacenamiento, uso, circulación o supresión.
            <br>• Transferencia: La transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de datos personales, ubicado en Colombia, envía la información o los datos personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.
            <br>• Transmisión: Tratamiento de datos personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un tratamiento por el Encargado por cuenta del Responsable.
            <br>POLITICA DE TRATAMIENTO Y PROTECCION DE DATOS PERSONALES
            <br><br>4. TRATAMIENTO Y FINALIDADES
            <br>El tratamiento que realizará la empresa TOTALSPORT SAS será el de recolectar, almacenar, procesar, usar, transmitir y/o transferir (según corresponda) los datos personales, atendiendo de forma estricta los deberes de seguridad y confidencialidad ordenados por la Ley 1581 de 2012 y el Decreto 1377 de 2013, con las siguientes finalidades:
            <br> Obtener propuestas comerciales de proveedores y contratistas.
            <br> Desarrollar los procesos administrativos de creacion, contratacion y gestion de proveedores y contratistas y en cumplimiento de normas aplicables, incluyendo, pero sin limitarse a las tributarias y comerciales.
            <br> Pago de obligaciones contractuales relacionadas con contratistas y proveedores.
            <br> Evaluar la viabilidad de establecer un vínculo comercial con el cliente potencial y el registro de dicha información en los sistemas de información.
            <br> Desarrollar los procesos de facturación.
            <br> Analizar tendencias de compras con el fin de orientar las adquisiciones de productos a los gustos particulares del cliente.
            <br> Comunicar por diversos medios promociones y descuentos.
            <br> Fidelización de clientes.
            <br> Enviar información relacionada con actualización de datos, noticias y productos ofrecidos por TOTALSPORT SAS, referente al vínculo comercial contraído.
            <br> Enviar información relacionada con eventos y actividades de TOTALSPORT SAS.
            <br> Ejecutar todo lo referente en virtud de la relación contractual, procesos de afiliación, pago de nóminas, liquidación de aportes a seguridad social, pago de parafiscales, comunicación interna, programas de bienestar, programas de inducción, reinducción y formación, integración al SG - SST y todas aquellas informaciones y actividades en las cuales se relacionan o vinculan los empleados y sus familiares.
            <br> Realizar todas las gestiones y/o trámites internos de la compañía para llevar a cabo los procesos de selección.
            <br> En atención a organismos de control fiscal, legal o comercial que requieran información contenida en la base de datos de TOTALSPORT SAS.
            <br> Dar respuesta a contravenciones de tipo juridico.
            <br> En cumplimiento de mandatos administrativos, judiciales o legales.
            <br> En el ejercicio de los deberes y derechos en calidad de accionistas.
            <br> Emitir las certificaciones relativas a la relación del titular del dato con la compañía.
            <br> Salvaguardar las personas, instalaciones, bienes de la compañía y de terceros.
            <br> Controlar el acceso a las instalaciones físicas,
            <br> Para eventualmente contactar, vía telefónica, correo electrónico, o por cualquier otro medio, a personas naturales con quienes tiene o ha tenido relación, tales como, sin que la enumeración signifique limitación, trabajadores y familiares de éstos, proveedores y contratistas, clientes, aspirantes en procesos de selección, y demás partes interesadas para las finalidades antes mencionadas.
            <br> Cualquier otra finalidad que resulte en el desarrollo del contrato o la relación entre el titular e TOTALSPORT SAS.
            <br> Para el desarrollo de procesos administrativos propios de su actividad misional, por lo cual, cuando asi lo requiera, podrá contratar para que los servicios sean prestados por terceros, en estos casos la información se trasladará con las medidas de seguridad necesarias que permitan salvaguardar la intimidad del titular y la correcta prestación del servicio, y el cumplimiento de los demás deberes legales y constitucionales.
            <br><br>5. DERECHOS DEL TITULAR DE LOS DATOS PERSONALES
            Como titular de datos personales, se tiene derecho a:
            <br>a) Conocer, actualizar y rectificar sus datos personales frente a los Responsables del Tratamiento o Encargados del Tratamiento. Este derecho se podrá ejercer, entre otros frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error, o aquellos cuyo Tratamiento esté expresamente prohibido o no haya sido autorizado.
            <br>b) Solicitar prueba de la autorización otorgada al Responsable del Tratamiento salvo cuando expresamente se exceptúe como requisito para el Tratamiento, de conformidad con lo previsto en el artículo 10 de la presente ley.
            <br>c) Ser informado por el Responsable del Tratamiento o el Encargado del Tratamiento, previa solicitud, respecto del uso que les ha dado a sus datos personales.
            <br>d) Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la presente ley y las demás normas que la modifiquen, adicionen o complementen.
            <br>e) Revocar la autorización y/o solicitar la supresión del dato cuando en el Tratamiento no se respeten los principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando la Superintendencia de Industria y Comercio haya determinado que en el Tratamiento el Responsable o Encargado han incurrido en conductas contrarias a esta ley y a la Constitución.
            <br>f) Acceder en forma gratuita a sus datos personales que hayan sido objeto de Tratamiento.
            <br><br>6. ÁREA RESPONSABLE DE LA ATENCIÓN DE PETICIONES, CONSULTAS Y RECLAMOS SOBRE DATOS PERSONALES
            La empresa TOTALSPORT SAS tiene a su cargo la labor de desarrollo, implementación, socialización y seguimiento de esta Política. El área designada para la atención a Consultas y reclamos de los titulares de la información es la Gerencia de Operaciones.
            <br><br>7. PROCEDIMIENTO PARA ATENCIÓN Y RESPUESTA A PETICIONES, CONSULTAS,
            QUEJAS Y RECLAMOS DE LOS TITULARES DE DATOS PERSONALES
            Los Titulares de los Datos Personales que estén siendo recolectados, almacenados, procesados, usados, transmitidos y/o transferidos por la empresa TOTALSPORT SAS podrán ejercer en cualquier momento sus derechos a conocer, actualizar, rectificar su información y revocar el consentimiento para el tratamiento de sus datos.
            Para el efecto, se seguirá el siguiente procedimiento, de conformidad con la Ley de Protección de Datos Personales:
            <br>a. Medios habilitados para la presentación de peticiones, consultas, quejas y reclamos: La empresa TOTALSPORT SAS ha dispuesto los siguientes medios para la recepción y atención de peticiones, consultas, quejas y reclamos que permiten conservar prueba de las mismas:
            <br>• Comunicación escrita dirigida La empresa TOTALSPORT SAS a la Transversal 93 #53 – 48 Bodega 75/76, Bogotá – Colombia.
            <br>• Comunicación escrita vía correo electrónico:
            <br>b. Atención y respuesta a peticiones y consultas:
            El Titular o su apoderado, podrán solicitar a la empresa TOTALSPORT SAS:
            <br>• Información sobre los Datos Personales del Titular que son objeto de Tratamiento.
            <br>• Información respecto del uso que se le ha dado a su información de carácter personal.
            Las peticiones y consultas serán atendidas en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de las mismas. Cuando no fuere posible atender la petición o consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando cuando se atenderá su petición o consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
            <br>c. Atención y respuesta a quejas y reclamos:
            El Titular o sus apoderados, podrán solicitar a la empresa TOTALSPORT SAS a través de una queja o reclamo presentado mediante los canales ya indicados:
            <br>• La corrección o actualización de la información.
            <br>• Que se subsane o corrija el presunto incumplimiento a cualquiera de los deberes contenidos en la Ley de Protección de Datos Personales.
            La solicitud deberá contener como mínimo la descripción de los hechos que dan lugar a la queja o reclamo, la dirección y datos de contacto del solicitante. Toda solicitud, para ser tramitada, debe presentarse por el titular de los datos o por su representante legal; por lo tanto, TOTALSPORT SAS se reserva el derecho de verificar la identidad del peticionario mediante cualquier medio.
            Si la queja o reclamo se presentan incompletos, la empresa TOTAL SAS deberá requerir al interesado dentro de los cinco (5) días siguientes a la recepción de la queja o reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido de la queja o reclamo.
            Una vez recibida la queja o reclamo completo, se incluirá en la Base de Datos, en el aparte correspondiente, una leyenda que diga "reclamo en trámite" y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que la queja o reclamo sea resuelto.
            El término máximo para atender la queja o el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender la queja o el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá la queja o reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.
            <br><br>8. VIGENCIA
            Esta Política de Tratamiento y Protección de Datos Personales ha entrado en vigencia desde el 1 de Octubre de 2018.
            Cualquier cambio sustancial en esta política de tratamiento, en los términos descritos en el artículo 5º del Decreto 1377 de 2013, deberá ser comunicado oportunamente a los titulares de los datos personales de una manera eficiente, antes de implementar las nuevas políticas.
            </div>
            <div style="text-align:center;"><button (click)="modal_invisible()" class="btn btn-primary">Aceptar Terminos y condiciones</button></div> 
          </div>
        </div>
      </div>
      <div class="modal" id="modal" *ngIf="this.botonsito == '1'">
        <div class="modal-content" style="width: 20%!important;"> 
          <div style="width:100%;"><img src="https://facturacion.totalsportsas.com/img/tenor.gif" width="100%"></div>
          <div>Subiendo archivos porfavor espere</div>
        </div>
      </div> 
      <button class="btn btn-primary" id="botonsito" [disabled]="this.botonsito == '1'" (click)="save()" style="margin:10px;" >Enviar</button>
      
    </form>
</div>
<div style="width: 100%;text-align: center;margin-top: 10px;">
  <label>
    Desarrollo Web por: <a href="https://systemsweb.net/">System & Studio Web</a>
  </label>
</div>