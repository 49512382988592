import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Component, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TotalSport';
  url = `https://facturacion.totalsportsas.com/register.php`;
  trueimg:Boolean = false;
  loader:Boolean = false;
  final:Boolean = true;
  botonsito = '0';


  perfil = new FormGroup({
    razonSocial: new FormControl('',[Validators.required]),
    tipoDocumento: new FormControl('0',[Validators.required]),
    documento: new FormControl('',[Validators.required]),
    dv: new FormControl('0',[Validators.maxLength(1)]),
    regimen: new FormControl('0',[Validators.required]),
    responsableIva: new FormControl(false),
    autoRetenedor: new FormControl(false),
    granContribuyente: new FormControl(false),
    actividadEconomica: new FormControl('',[Validators.required]),
    email: new FormControl('',[Validators.email]),
    telefono: new FormControl('',[Validators.required]),
    celular: new FormControl('',[Validators.required]),
    direccion: new FormControl('',[Validators.required]),
    ciudad: new FormControl('0',[Validators.required]),
    file: new FormControl(''),
    fileSource: new FormControl(null),
    file2: new FormControl(''),
    fileSource2: new FormControl(null),
    file3: new FormControl(''),
    fileSource3: new FormControl(null),
    confirm:new FormControl(false,[Validators.required]),
    confirm2:new FormControl('0')
  });

  constructor(private http:HttpClient){
    var resultado='';
  }


  subirImagen(event: any){

    var name = this.perfil.get('file')?.value;
    var ext = name.split(".");
    var ext1 = ext[1];

    if(ext1 != 'pdf' && ext1 != 'png' && ext1 && 'jpg'){
      alert('Solo se permite formato pdf o imagen');
      this.perfil.patchValue({file:''});
    } else {
      if (event.target.files.length > 0) {
        var file=event.target.files[0];
        getBase64(file).then(
          data => this.perfil.patchValue({
            fileSource: data
          })
        );
      }
    }
  }

  subirImagen2(event: any){
    var name = this.perfil.get('file2')?.value;
    var ext = name.split(".");
    var ext1 = ext[1];

    if(ext1 != 'pdf' && ext1 != 'png' && ext1 && 'jpg'){
      alert('Solo se permite formato pdf o imagen');
      this.perfil.patchValue({file2:''});
    } else {
      if (event.target.files.length > 0) {
          var file2=event.target.files[0];
        getBase64(file2).then(
          data => this.perfil.patchValue({
            fileSource2: data
          })
      
        );
      }
    }
  }
  subirImagen3(event: any){
    var name = this.perfil.get('file3')?.value;
    var ext = name.split(".");
    var ext1 = ext[1];

    if(ext1 != 'pdf' && ext1 != 'png' && ext1 && 'jpg'){
      alert('Solo se permite formato pdf o imagen');
      this.perfil.patchValue({file3:''});
    } else {
      if (event.target.files.length > 0) {
        var files=event.target.files[0];
        getBase64(files).then(
          data => this.perfil.patchValue({
            fileSource3: data
          })
        );
      }
    }
  }

  save(){
    
    interface Config {
      status: string;
    }
    
    if(this.perfil.get('file')?.value==''){
      this.perfil.patchValue({
        fileSource:'',
      })
    }
    if(this.perfil.get('file2')?.value==''){
      this.perfil.patchValue({
        fileSource2:'',
      })
    }
    if(this.perfil.get('file3')?.value==''){
      this.perfil.patchValue({
        fileSource3:'',
      })
    }

    if(this.perfil.get('tipoDocumento')?.value=='nit'){
      if(this.perfil.get('dv')?.value=='0'){
        alert('Porfavor ingrese el digito de verificacion de su nit');
        return;
      }
    }

    if(this.perfil.invalid){
      alert('Faltan campos por completar');
      return;
    }
     
    if(this.perfil.get('confirm')?.value==false){
      alert('Debes aceptar los terminos y condiciones');
      this.perfil.patchValue({
        confirm2:1
      })
      return; 
    }
    this.botonsito = '1';
    this.http.post<Config>(this.url,this.perfil.value,{}).subscribe({
      next: data => {
        console.warn(data);
        switch (data["status"]){
          case "exito": {
          alert('Hemos recibido su solicitud, pronto le estaremos dando una respuesta');
          this.perfil.patchValue({
            razonSocial:'',documento:'',tipoDocumento:'0',dv:'0',regimen:'0',responsableIva:false,autoRetenedor:false,granContribuyente:false,
            actividadEconomica:'',email:'',telefono:'',celular:'',direccion:'',ciudad:'0',file:'',fileSource:'',file2:'',fileSource2:'',file3:'',
            fileSource3:'',confirm:false
          })
          break; 
          }
          case "err":{
            alert('Algo ha salido mal, reintente mas tarde');
            break;
          }
          case "documentos": {
            alert('Debe subir los documentos que le hacen falta');
            break;
          }
          case "factura": {
            alert('Debe subir la factura');
            break;
          }
        }
        this.botonsito = '0';
      },
      error: error =>{
        console.error(error);
        this.botonsito = '0';
      }
    });

  }

  modal_visible(){
    this.perfil.patchValue({
      confirm2:'1',
    })
  }
  modal_invisible(){
    this.perfil.patchValue({
      confirm:true,
      confirm2:'0',
    })
  }

}

function getBase64(file:any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = error => reject(error);
  });
}